<template>
        <a-modal v-model="visible" title="修改序号" @ok="handleOk" okText="确定" cancelText="取消" @cancel="handleCancel">
          <a-form>
            <a-form-item label="清洁屋序号">
                <a-input size="large" placeholder="输入清洁屋序号" v-model="record.sort"/>
            </a-form-item>
          </a-form>
        </a-modal>
</template>

<script>
import {updateCollectionHouseCodeApi} from '@/api/collection'
export default {
    data() {
        return {
            visible: false,
            record: {}
        }
    },
    methods: {
        showModal(record) {
            this.record = record
            this.visible = true
        },
        handleOk(e) {
            updateCollectionHouseCodeApi({...this.record}).then(res => {
                this.$emit('query')
                this.visible = false
            })
        },
        handleCancel(e) {
            this.record = {}
            this.$emit('query')
            this.visible = false
        }
    }
}
</script>