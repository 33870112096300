<template>
    <div class="index">
        <a-form layout="inline">
            <a-form-item label="采集点名称" ><a-input placeholder="输入采集点名称" v-model="params.name" @change="onSearch" allow-clear/></a-form-item>
            <a-form-item label="归集点名称" ><a-input placeholder="输入归集点名称" v-model="params.residentialName" @change="onSearch" allow-clear/></a-form-item>
            <a-form-item label="行政区划">
             <a-cascader :options="options" placeholder="选择行政区划" disabled/>
            </a-form-item>
            <a-form-item> 
              <a-button type="primary" @click="onSearch">  查询 </a-button>
              <a-button type="primary" style="margin-left: 10px;" @click="uploadCollection">  批量导入 </a-button>
              <a-button type="primary" style="margin-left: 10px;" @click="deleteCollection">  批量删除 </a-button>
            </a-form-item>
        </a-form>
        <a-table size="small" bordered :scroll="{  y: 600} " :columns="columns" :data-source="tableData" style="margin-top: 20px;" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :loading="loading" :pagination="pagination" >
            <span slot="action" slot-scope="text, record">
                <a @click="toEdit(record)" style="margin-right: 10px;">编辑</a>
                <a @click="refresh(record)" style="margin-right: 10px;">刷新基础信息</a>
                <a @click="updateCode(record)">修改市级编码</a>
                <a @click="updateSort(record)">修改清洁屋序号</a>
            </span>
        </a-table>
        <a-modal v-model="visible" title="修改市级编码" @ok="handleOk" okText="确定" cancelText="取消">
          <a-form>
            <a-form-item label="市级编码">
                <a-input size="large" placeholder="输入市级编码" v-model="currentItem.administrativeNumber"/>
            </a-form-item>
          </a-form>
        </a-modal>
        <update-house-sort ref="updateHouseSort" @query="queryCollection"/>
        <upload-comm ref="uploadComm" title="上传采集点数据" action="https://www.zhongshihudong.com/garbage-collect-server/collection/point/upload" @querydata="queryCollection"></upload-comm>
    </div>
</template>

<script>
import updateHouseSort from '@/components/collectionPointMgt/component/updateHouseSort'
import uploadComm from '../common/uploadComm'
import obj from './dataStructure'
import { queryCollectionList , updateCollectionCodeApi, deleteCollectionApi, refreshCollectionApi} from '@/api/collection'
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = obj.columns
export default {
  components: { uploadComm, updateHouseSort },
  data() {
    return {
      currentItem: {},
      visible: false,
      jsonData: [],
      jsonFields: {},
      tableDataBak: [],
      selectedRowKeys: [],
      confirmLoading: false,
      showModal: false,
      params: {},
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        onChange: (current) => this.changePage(current)
      },
      loading: false,
       rowSelection,
       options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                },
              ],
            },
          ],
        },
       ],
      divisions: ['苏州市', '吴中区'],
      value: [],
      tableData: [],
      columns,
    };
  },
  mounted() {
    this.queryCollection()
    this.queryAllCollection()
  },
  methods: {
    updateSort(record){
      this.$refs.updateHouseSort.showModal(record)
    },
    handleOk() {
      updateCollectionCodeApi({id: this.currentItem.id, administrativeNumber: this.currentItem.administrativeNumber}).then(res => {
        if(res.code == '0') {
          this.$message.success('修改成功')
          this.visible = false
          this.queryCollection()
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    updateCode(record){
      this.visible = true
      this.currentItem = record
    },
    refresh(record){
      refreshCollectionApi(record.systemCode).then(res => {
        this.$message.success('刷新成功')
      })
    },
    queryAllCollection() {
      let param = {
        page: 1,
        limit: 2000
      }
      this.jsonFields = {}
      this.columns.forEach((item, index) => {
        if(index < this.columns.length - 1) {
          this.jsonFields[item.title] = item.dataIndex
        }
      })
      queryCollectionList({...param}).then(res => {
        this.filterTableData(res.result.content, 'jsonData')
      })
    },
    toEdit(record) {
      this.tableDataBak.forEach(item => {
        if(item.systemCode == record.systemCode)
         this.$router.push({path: '/viewport/collectionPointDetail', query: {record: item}})
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    deleteCollection() {
      let systemCodes = []
      this.selectedRowKeys.forEach(item => {
        systemCodes.push(this.tableData[item].id)

      })
      deleteCollectionApi(systemCodes).then(res => {
        this.$message.success('删除成功')
        this.pagination.current = 1
        this.pagination.total = 0
        this.queryCollection()
        this.selectedRowKeys = []

      })
      .catch(() => {
        this.$message.error('删除失败')
      })
    },
    onSearch() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.queryCollection()
    },
    uploadCollection() {
      this.$refs.uploadComm.showModal()
    },
    changePage(current) {
      this.pagination.current = current
      this.queryCollection()
    },
    queryCollection() {
      this.loading = true
      this.params.page = this.pagination.current
      this.params.limit = this.pagination.pageSize
      queryCollectionList({...this.params}).then(res => {
        this.loading = false
        this.filterTableData(res.result.content, 'tableData')
        this.tableDataBak = res.result.content
        this.pagination.current = res.result.number + 1
        this.pagination.total = res.result.totalElements
      })
    },
    filterTableData(data, name) {
      this[name] = []
      let tmpArr = []
      let tmpObj = {}
      data.forEach((item, index) => {
        tmpObj.id = item.id
        tmpObj.sort = item.information.sort
        tmpObj.systemCode = item.systemCode
        tmpObj.name = item.information.name
        tmpObj.residentialCode = item.information.residentialCode
        tmpObj.residentialName = item.information.residentialName
        tmpObj.address = item.information.address.address
        tmpObj.pointType = item.information.pointType
        tmpObj.longitude = item.coordinate.longitude
        tmpObj.latitude = item.coordinate.latitude
        tmpObj.director = item.meteringInfo.director
        tmpObj.phone = item.meteringInfo.phone
        tmpObj.administrativeNumber = item.administrativeNumber
        tmpArr.push(tmpObj)
        tmpObj = {}
      })
      this[name] = tmpArr
    }
  }
};
</script>

<style scoped>
.index{
    /* background: #F5F7F9; */
    padding: 15px;
    box-sizing: border-box;
    /* wsystemCodeth: 100%; */
    min-height: 100%;
}
.ant-form{
    text-align: initial;
}

</style>