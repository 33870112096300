export default {
    columns: [
        {
            title: '系统编号',
            dataIndex: 'systemCode',
            key: 'systemCode'
        },
        {
            title: '市级编码',
            dataIndex: 'administrativeNumber',
            key: 'administrativeNumber'
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 0
        },
        {
            title: '采集点名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '归集点编号',
            dataIndex: 'residentialCode',
            key: 'residentialCode'
        },
        {
            title: '归集点名称',
            dataIndex: 'residentialName',
            key: 'residentialName'
        },
        // {
        //     title: '地址',
        //     dataIndex: 'address',
        //     key: 'address',
        //     ellipsis: true,
        // },
        // {
        //     title: '类型',
        //     dataIndex: 'pointType',
        //     key: 'pointType'
        // },
        {
            title: '采集点序号',
            dataIndex: 'sort',
            key: 'sort',
            width: 120,
        },
            
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 250,
            scopedSlots: { customRender: 'action' },
        }
    ],
    detailItems: [
        {
            label: '系统编号',
            key: 'systemCode',
            type: 'input',
            disabled: true
        },
        {
            label: '采集点名称',
            key: 'name',
            type: 'input',
            disabled: false
        },
        {
            label: '归集点',
            key: 'residentialName',
            type: 'input',
            disabled: true,
        },
        {
            label: '所属区域',
            key: 'areaName',
            type: 'input',
            disabled: true
        },
        {
            label: '所属街道',
            key: 'streetName',
            type: 'input',
            disabled: true
        },
        // {
        //     label: '采集点数量',
        //     key: 'collectionPoint',
        //     type: 'input',
        //     disabled: false,
        // },
        {
            label: '采集点设备',
            key: 'equipments',
            type: 'click',
            disabled: false
        },
        {
            label: '计量状态',
            key: 'meteringStatus',
            type: 'input',
            disabled: true
        },
    ]
}